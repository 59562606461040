import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I am a creative Front end devloper based in Tel Aviv, specialise in creating ineractive experiences and functional interfaces.`}</p>
    <p>{`Main Skills
Frontend development: JavaScript, CSS, HTML, React, React Native, Ruby On Rails.
Backend devlopment: Ruby, Node.js`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      