import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Say Hi or find me on other platforms: `}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:asi.meir@gmail.com"
      }}>{`asi.meir@gmail.com`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/asi-meir-3bb97743"
      }}>{`linkedin`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://github.com/prayingmantislab"
      }}>{`Github`}</a><br />{`
`}<a parentName="p" {...{
        "href": "tel:0548846888"
      }}>{`054-8846888`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      