import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`#PROJECTS`}</p>
    <ProjectCard title="Crown Clothing Store" link="https://mantis-online-store.herokuapp.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  An E-commerce app for a clothing brand. Built with React Redux, Hooks, Stripe,
  Firebase
    </ProjectCard>
    <ProjectCard title="Decision-Maker" link="https://play.google.com/store/apps" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  An app that helps you decide on hard decisions and returns a randomly yes no
  question with a gif from various celebrities, published to Google PLay store.
  Built with React Native and the yes/no wtf API.
    </ProjectCard>
    <ProjectCard title="Just swish" link="https://www.justswish.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  One stop shop for the NBA fans, Dashboard containing customized YouTube,
  Twitter, forum and stats of NBA Teams and players. Frontend stack: Ruby on
  Rails, JavaScript , HTML, Css Backend stack: Ruby build in collaboration with
  Aharon wolf, Siel goldfarb and Ron Artenstein
    </ProjectCard>
    <ProjectCard title="Commercial-carpentry-design" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A website for a commercial carpentry brand. Built with wordpress using the
  Elementor builder.
    </ProjectCard>
    <ProjectCard title="Sesshin" link="https://github.com/liranmeir/Sesshin" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A mobile massage booking service, book your massage at home in a clinique or
  in the office. Content management system to manage the clients, therapists and
  posts.
    </ProjectCard>
    <ProjectCard title="DevConnector" link="https://github.com/prayingmantislab/devconnector" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  MERN stack application a social network app that includes authentication,
  profiles and forum posts.
    </ProjectCard>
    <ProjectCard title="Mr Cocktail" link="https://github.com/prayingmantislab/rails-mister-cocktail" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A web app that allows you to write and post cocktails recipes. stack: Rails
  5.2.4.2 Ruby 2.6.6 Devise Yarn PostgreSql Webpack Cloudinary Bootstrap Jquery
  Active Admin
    </ProjectCard>
    <ProjectCard title="LeftOvers" link="https://github.com/prayingmantislab" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A web app that allows restaurant managers to post and sell thier leftovers stocks to clients and rate the freshness level. 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      